
/**
 * @name: 统计分析-档口入库统计
 * @author: lili
 * @date: 2023-08-04 17:53
 * @description： 统计分析-档口入库统计
 * @update: 2023-08-04 17:53
 */
import {Component, Vue} from "vue-property-decorator"
import type {ICrudOption} from "@/types/m-ui-crud"
import {ICheckin, ICheckinParams, IProductDetailParams} from "@/apis/statistics/checkin/types";
import {deepCopy, exportFile, getPreAndNextTime} from "@/utils/common";
import {
  portEntryStatisticsPageApi,
  stallStatisticsExportApi,
  statisticsCallProductDetailApi,
  statisticsDistributeRecordDetailApi
} from "@/apis/statistics/checkin";

@Component({})
export default class statisticsCheckin extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: any = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: ICheckinParams = {
    page: 1,
    limit: 10,
    gateIdList: [],//	档口id集合
    productName: '',//	商品名称
    sortIdList: [],	//分类id集合,
    startTime: '',//	开始时间
    endTime: ''//	结束时间
  }
  // 表单参数
  modelForm: any = {
    page: 1,
    limit: 10,
    loading: false,
    tableData: []
  }
  dialogTitle: string = '';
  dialogVisible: boolean = false;
  dialogType: number = 1;
  // crud配置信息
  crudOption: ICrudOption = {
    menu: false,
    column: [
      {
        label: "日期",
        prop: "time",
        width: "150",
        align: "center",
        hide: true,
        search: true,
        type: "daterange",
        // @ts-ignore
        defaultTime: ["06:00:00", "05:59:59"],
        // @ts-ignore
        onSearchChange:()=>{
          this.getList();
        }
      },
      {
        label: "档口名称",
        prop: "gateIdList",
        search: true,
        searchMultiple: true,
        hide: true,
        type: "select",
        dicUrl: "/api/admin/gate/getGateList",
        dicFormatter: (res: any) => ({list: res.data, label: 'gateName', value: 'id'}),
      },
      {
        label: "档口名称",
        prop: "gateName",
        width: 150,
        align: "center"
      },
      {
        label: "商品名称",
        prop: "productName",
        search: true,
        align: "center",
        width: 150
      },
      {
        label: "采购单位",
        prop: "purchaseUnit",
        align: "center",
      },
      {
        label: "成本价1（按采购数计算）",
        prop: "costPriceOne",
        align: "center",
        width: 180
      },
      {
        label: "成本价2（按实际入库数计算）",
        prop: "costPriceTwo",
        align: "center",
        width: 210
      },
      {
        label: "档口配货数",
        prop: "distributeStock",
        slot: true,
        align: "center",
      },
      {
        label: "档口调入数",
        prop: "joinWeight",
        slot: true,
        align: "center",
      },
      {
        label: "档口调出数",
        prop: "outWeight",
        slot: true,
        align: "center",
      },
      {
        label: "档口实际入库数",
        prop: "practical",
        align: "center",
      },
    ]
  }

  /**
   * 详情
   * @param row
   * @param type //1-distributeStock ,2=joinWeight,3=outWeight
   */
  openDetail(row: ICheckin, index: number, type: number) {
    this.dialogTitle = type == 1 ? '档口配货详情' : type == 2 ? '档口调入详情' : '档口调出详情'
    this.dialogType = type;
    this.dialogVisible = true;
    this.modelForm.page=1;
    this.modelForm.gateName = row.gateName;
    this.modelForm.productName = row.productName;
    this.modelForm.gateId = row.gateId;
    this.modelForm.productId = row.productId;
    this.modelForm.totalQty = type == 1 ? row.distributeStock : type == 2 ? row.joinWeight : row.outWeight;

    if ( this.dialogType == 1) {
      this.getDistributeRecordDetail();
    } else {
      this.getCallProductDetail();
    }
  }

  /**
   * 档口配货 分页列表数据
   * @param ICheckinParams
   */
  getDistributeRecordDetail() {
    const form: any = {
      page: this.modelForm.page,
      limit: this.modelForm.limit,
      gateId: this.modelForm.gateId,
      productId: this.modelForm.productId,
  }
    if (this.queryParam.time && this.queryParam.time.length) {
      form.startTime = this.queryParam.time[0]
      form.endTime = this.queryParam.time[1]
    } else {
      form.startTime = ''
      form.endTime = ''
    }
    statisticsDistributeRecordDetailApi(form).then(e => {
      this.modelForm.tableData = e.list;
      this.modelForm.total = e.total;
      this.modelForm.loading = false;
    })
  }

  /**
   * 档口调入-调出  分页列表数据
   * @param IProductDetailParams
   */
  getCallProductDetail() {
    const form: IProductDetailParams = {
      page: this.modelForm.page,
      limit: this.modelForm.limit,
      outGateId: this.modelForm.gateId,
      joinGateId: this.modelForm.gateId,
      productId: this.modelForm.productId,
    }
    if (this.dialogType == 2) {
      delete form.outGateId
    } else
      delete form.joinGateId

    statisticsCallProductDetailApi(form).then(e => {
      this.modelForm.tableData = e.list;
      this.modelForm.total = e.total;
      this.modelForm.loading = false;
    })
  }

  /**
   * 切换分页 1页条数
   * @param val
   */
  handleSizeChange(val: number) {
    this.modelForm.limit = val;
    this.modelForm.loading = true;
    if ( this.dialogType == 1) {
      this.getDistributeRecordDetail();
    } else {
      this.getCallProductDetail();
    }
  }

  /**
   * 切换分页 页数
   * @param val
   */
  handleCurrentChange(val: number) {
    this.modelForm.page = val;
    this.modelForm.loading = true;
    if ( this.dialogType == 1) {
      this.getDistributeRecordDetail();
    } else {
      this.getCallProductDetail();
    }
  }

  /**
   * 导出excel
   */
  exportExcel() {
    const form: ICheckinParams = deepCopy(this.queryParam)
    if (this.queryParam.time && this.queryParam.time.length) {
      form.startTime = this.queryParam.time[0]
      form.endTime = this.queryParam.time[1]
    } else {
      form.startTime = ''
      form.endTime = ''
    }
    delete form.time
    if (!form.gateIdList) {
      form.gateIdList = []
    }
    if (!form.sortIdList) {
      form.sortIdList = []
    }
    stallStatisticsExportApi(form).then(e => {
      exportFile(e, '档口入库统计.xlsx')
    })
  }

  getList() {
    this.tableLoading = true
    const query: ICheckinParams = deepCopy(this.queryParam)
    if (this.queryParam.time && this.queryParam.time.length) {
      query.startTime = this.queryParam.time[0]
      query.endTime = this.queryParam.time[1]
    } else {
      query.startTime = ''
      query.endTime = ''
    }
    delete query.time
    if (!query.gateIdList) {
      query.gateIdList = []
    }
    if (!query.sortIdList) {
      query.sortIdList = []
    }
    portEntryStatisticsPageApi(query).then(e => {
      this.tableTotal = e.total
      this.tableData = e.list
      this.tableLoading = false
    })
  }

  reset() {
    const dateRange = getPreAndNextTime("06:00:00", "05:59:59")
    this.queryParam.time = dateRange;
    this.getList()
  }

  created() {
    this.reset()
  }
}
