/**
 * @name: 统计分析-档口入库统计接口文件
 * @author: lili
 * @date: 2023-08-04 17:46
 * @description： 统计分析-档口入库统计接口文件
 * @update: 2023-08-04 17:46
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import {
  ICheckin,
  ICheckinParams,
  IProduct,
  IProductDetail,
  IProductDetailParams
} from "@/apis/statistics/checkin/types";
/**
 * 档口入库统计查询
 * @param param
 * @returns
 */
export const portEntryStatisticsPageApi = (param: ICheckinParams) => get<IPageRes<ICheckin>>("/admin/statistics/portEntryStatistics", param)
/**
 * 档口入库统计导出
 * @param data
 * @returns
 */
export const stallStatisticsExportApi = (data: ICheckinParams) => postJ("/admin/statistics/exportPortEntryStatisticsExcel", data, "blob")


/**
 * 档口配货详情
 * @param param
 * @returns
 */
export const statisticsDistributeRecordDetailApi = (param: ICheckinParams) => get<IPageRes<IProduct>>("/admin/statistics/distributeRecordDetail", param)

/**
 * 档口调入-调出数详情
 * @param param
 * @returns
 */
export const statisticsCallProductDetailApi = (param: IProductDetailParams) => get<IPageRes<IProductDetail>>("/admin/statistics/callProductDetail", param)
